import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Dialog } from '@reach/dialog';
import { t } from '@lingui/macro';
import { I18n } from '@lingui/react';

import Image from './Image';
import styles from './Gallery.module.css';

const query = graphql`
  query {
    screenshots: allFile(filter: { absolutePath: { regex: "/screenshots/" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1158) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

class Gallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLightbox: false,
      selectedImage: null,
    };
  }

  render() {
    const { showLightbox, selectedImage } = this.state;

    return (
      <StaticQuery
        query={query}
        render={data => {
          const screenshots = data.screenshots.edges;

          return (
            <div className={styles.gallery}>
              {screenshots.map(image => (
                <div
                  key={image.node.childImageSharp.fluid.src}
                  onClick={() => {
                    this.setState({ showLightbox: true, selectedImage: image });
                  }}>
                  <I18n>
                    {({ i18n }) => (
                      <Image node={image.node} alt={i18n._(t`Screenshot`)} />
                    )}
                  </I18n>
                </div>
              ))}
              {showLightbox && (
                <Dialog
                  className={styles.modal}
                  onDismiss={() => this.setState({ showLightbox: false })}
                  onClick={() => this.setState({ showLightbox: false })}>
                  <I18n>
                    {({ i18n }) => (
                      <Image
                        node={selectedImage.node}
                        className={styles.image}
                        alt={i18n._(t`Screenshot`)}
                      />
                    )}
                  </I18n>
                </Dialog>
              )}
            </div>
          );
        }}
      />
    );
  }
}

export default Gallery;
