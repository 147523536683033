import React from 'react';

const Footer = () => (
  <footer className="footer">
    <div>
      &copy; 2017{' '}
      <a href="http://thedangerouskitchen.co.uk">The Dangerous Kitchen</a>
    </div>
    <div>
      Powered by <a href="http://www.pumpkinseed.io">Pumpkinseed.io</a>
    </div>
  </footer>
);

export default Footer;
