import React from 'react';
import GatsbyImage from 'gatsby-image';

export default ({ node, ...props }) => {
  if (node.childImageSharp && node.childImageSharp.fluid) {
    if (node.childImageSharp.fluid.presentationWidth) {
      return (
        <GatsbyImage
          fluid={node.childImageSharp.fluid}
          {...props}
          style={{
            maxWidth: node.childImageSharp.fluid.presentationWidth,
            ...(props.style || {}),
          }}
        />
      );
    }
    return <GatsbyImage fluid={node.childImageSharp.fluid} {...props} />;
  }

  if (node.childImageSharp && node.childImageSharp.fixed) {
    return <GatsbyImage fixed={node.childImageSharp.fixed} {...props} />;
  }

  return <img src={node.publicURL} alt={props.alt} {...props} />;
};
