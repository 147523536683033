import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faYoutube,
  faTwitter,
  faFacebook,
} from '@fortawesome/free-brands-svg-icons';
import { Trans } from '@lingui/macro';
import Image from './Image';

const query = graphql`
  query {
    deMamboFace: file(relativePath: { eq: "DeMamboFace.png" }) {
      childImageSharp {
        fixed(width: 32, height: 32) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    flagJapan: file(relativePath: { eq: "FlagJapan.png" }) {
      childImageSharp {
        fixed(width: 40, height: 20) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    flagBritain: file(relativePath: { eq: "FlagBritain.png" }) {
      childImageSharp {
        fixed(width: 40, height: 20) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

class Header extends React.Component {
  constructor() {
    super();

    this.state = {
      menuActive: false,
    };
  }

  render() {
    return (
      <StaticQuery
        query={query}
        render={data => (
          <>
            <a
              href="#menu"
              id="menuLink"
              className={`menu-link ${!this.state.menuActive && 'inactive'}`}
              onClick={e => {
                e.preventDefault();
                this.setState({ menuActive: !this.state.menuActive });
              }}>
              <span />
            </a>
            <div className="header-container">
              <div className="header-background-container">
                <div className="header-background">
                  <a href="#top">
                    <Image node={data.deMamboFace} alt="De Mambo face" />
                  </a>
                </div>
              </div>
              <header
                id="menu"
                className={`header ${!this.state.menuActive && 'inactive'}`}>
                <nav className="navbar">
                  <div className="left-nav">
                    <ul className="nav-group nav-languages">
                      <li>
                        <Link to="/ja">
                          <Image node={data.flagJapan} alt="日本語" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/en">
                          <Image node={data.flagBritain} alt="English" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="right-nav">
                    <ul className="nav-group nav-links">
                      <li className="text-link">
                        <a
                          href="http://thedangerouskitchen.co.uk/how-to-de-mambo/"
                          target="_blank"
                          rel="noopener noreferrer">
                          <Trans>how to de mambo</Trans>
                        </a>
                      </li>
                    </ul>
                    <ul className="nav-group nav-social">
                      <li>
                        <a
                          href="https://twitter.com/TDKitchen"
                          target="_blank"
                          rel="noopener noreferrer">
                          <FontAwesomeIcon icon={faTwitter} />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/TDKitchen"
                          target="_blank"
                          rel="noopener noreferrer">
                          <FontAwesomeIcon icon={faFacebook} />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/channel/UCs37S4ey9KlgaUJ2njPjWNQ"
                          target="_blank"
                          rel="noopener noreferrer">
                          <FontAwesomeIcon icon={faYoutube} />
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </header>
            </div>
          </>
        )}
      />
    );
  }
}

export default Header;
