import React from 'react';
import { Player, BigPlayButton } from 'video-react';

import 'video-react/dist/video-react.css';

class VideoPlayer extends React.Component {
  render() {
    const { poster, video } = this.props;

    const posterUrl = poster.childImageSharp.fixed.src;

    return (
      <div className="screen">
        <div className="video-container" data-vjs-player>
          <Player preload="metadata" id="trailer-video" poster={posterUrl}>
            <BigPlayButton disabled />
            <source src={video} type="video/mp4" />
          </Player>
        </div>
      </div>
    );
  }
}

export default VideoPlayer;
